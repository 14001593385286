import React from 'react';
import { Link } from 'react-router-dom';

import buzzinLogo from './images/buzzinLogo.png'
import typesetterLogo from './images/typesetter.png'
import ParticlesBackground from './ParticlesBackground';

function HomePage() {
    return (
        <div style={styles.container}>
            <ParticlesBackground />
            <div style={styles.blurredCard}>
                <h1 style={styles.title}>Currently Available iOS Apps</h1>
                <div style={styles.buttonContainer}>
                    <div style={styles.logoContainer}>
                        <Link to="/typesetter">
                            <img
                            src={typesetterLogo}
                            alt="Typesetter Logo"
                            style={styles.logoImage}
                            />
                        </Link>
                    </div>
                    <div style={styles.logoContainer}>
                        <Link to="/buzzin">
                            <img
                            src={buzzinLogo}
                            alt="Buzzin Logo"
                            style={styles.logoImage}
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
      );
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'fixed',
        top: 0,
        left: 0,
    },
    blurredCard: {
        display: 'flex',
        background: 'rgba(255, 255, 255, 0.01)',
        backdropFilter: 'blur(5px)',
        borderRadius: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80vw',
        maxWidth: '500px',
        height: '40vh',
    },
    title: {
        fontSize: '1.5em',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row',
        width: '70%',
    },
    logoContainer: {
        padding: '5px',
    },
    logoImage: {
        width: '100px',
        height: '100px',
        maxWidth: '100%',
        objectFit: 'contain',
    }
}

export default HomePage;